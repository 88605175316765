import { Box, Stack, UnstyledButton } from "@mantine/core";
import { IFooterMenuGroup } from "entities/footer/types";
import { StyledLink } from "shared/ui/Link";
import Typography from "shared/ui/Typography/Typography";

type Props = IFooterMenuGroup;
function FooterMenuGroup({ title, content }: Props): JSX.Element {
    return (
        <Box maw={250} miw={225}>
            <Typography tag="mCaps">{title}</Typography>
            <Stack mt={15} spacing={5} align="flex-start">
                {content.map((item, index) => {
                    if (item.href) {
                        return (
                            <StyledLink
                                key={`${item.name}_${index}`}
                                href={item.href}
                                isExternalLink={item.isExternalLink}>
                                <Typography
                                    tag="mSemibold"
                                    sx={{
                                        ":hover": {
                                            fontWeight: "bold",
                                        },
                                    }}>
                                    {item.name}
                                </Typography>
                            </StyledLink>
                        );
                    }
                    return (
                        <UnstyledButton key={`${item.name}_${index}`} onClick={item.onClick}>
                            <Typography
                                tag="mSemibold"
                                sx={{
                                    ":hover": {
                                        fontWeight: "bold",
                                    },
                                }}>
                                {item.name}
                            </Typography>
                        </UnstyledButton>
                    );
                })}
            </Stack>
        </Box>
    );
}
export default FooterMenuGroup;
