import { Collapse, Flex } from "@mantine/core";
import CategoriesSectionSkeleton from "entities/header/CategoriesSectionSkeleton";
import { CategoryItem } from "entities/header/CategoryItem";
import useCategories from "shared/api/categories/hooks/queries/useCategories";

export function CategoriesSection() {
    const { data, isLoading } = useCategories();

    return (
        <>
            <Collapse in={isLoading}>
                <CategoriesSectionSkeleton />
            </Collapse>
            <Collapse in={!isLoading}>
                <Flex justify="center" maw="calc(100vw - 57px)" w={1520} wrap="wrap" miw={1519}>
                    {data?.data.map((item) => (
                        <CategoryItem category={item} key={item.id} />
                    ))}
                </Flex>
            </Collapse>
        </>
    );
}
