import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationKeys, QueryKeys } from "shared/api/keys";
import { usersApi } from "../../requests";
import { CreateUserContactRequest } from "../../types";

export const useCreateUserContact = () => {
    const queryClient = useQueryClient();
    return useMutation(
        [MutationKeys.CREATE_USER_CONTACT],
        (data: CreateUserContactRequest) => usersApi.createUserContact(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([QueryKeys.USER_CONTACTS_INDEX]);
            },
        }
    );
};
