import { Box, Header as MantineHeader, MediaQuery } from "@mantine/core";
import { CategoriesSection } from "features/header/ui/CategoriesSection";
import { TopSection } from "features/header/ui/TopSection";

export function Header() {
    return (
        <MediaQuery query="print" styles={{ display: "none" }}>
            <MantineHeader
                height="auto"
                withBorder={false}
                pt={25}
                sx={{
                    zIndex: 102,
                }}>
                <TopSection />
                <Box mt={25}>
                    <CategoriesSection />
                </Box>
            </MantineHeader>
        </MediaQuery>
    );
}
