import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationKeys, QueryKeys } from "shared/api/keys";
import { usersApi } from "../../requests";
import { DeleteUserContactRequest } from "../../types";

export const useDeleteUserContact = () => {
    const queryClient = useQueryClient();
    return useMutation(
        [MutationKeys.DELETE_USER_CONTACT],
        (data: DeleteUserContactRequest) => usersApi.deleteUserContact(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([QueryKeys.USER_CONTACTS_INDEX]);
            },
        }
    );
};
