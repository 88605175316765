import { Box, packSx } from "@mantine/core";
import { BoxProps } from "@mantine/core/lib/Box/Box";
import { contentMaxWidth, sidePadding } from "styles/index";

function MaxWidthLayout({ sx, ...props }: BoxProps): JSX.Element {
    return (
        <Box
            sx={[
                {
                    position: "relative",
                    maxWidth: contentMaxWidth + sidePadding * 2,
                    paddingLeft: sidePadding,
                    paddingRight: sidePadding,
                    marginLeft: "auto",
                    marginRight: "auto",
                },
                ...packSx(sx),
            ]}
            {...props}
        />
    );
}
export default MaxWidthLayout;
