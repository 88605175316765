import { BaseApi } from "shared/api/baseApi";
import {
    $Contractor,
    $Discount,
    $GetUserContactRoleResources,
    $Statistic,
    CreateContractorRequest,
    CreateUserContactRequest,
    DeleteUserContactRequest,
    GetUserContactRoleResources,
    UpdatePasswordRequest,
    UpdateUserContactRequest,
    UpdateUserDataRequest,
    UserContactsResponse,
    UsersResponse,
} from "shared/api/user/types";
import { instance } from "src/application/config/axios";
import { z } from "zod";
import { $AdaptedPaginatedResponse, $AdaptedResponse } from "../types";

/**
 * Используйте CRUD наименования. Например:
 *  - НЕ getUsers а indexUsers.
 *  - НЕ getUser а showUser.
 *  - НЕ editUser а updateUser
 *  - НЕ removeUser а deleteUser
 */

class UsersApi extends BaseApi {
    async indexUsers(params: unknown): Promise<UsersResponse> {
        return this.instance.get("users", { params: params });
    }
    async updateUserData(newData: UpdateUserDataRequest) {
        await this.instance.put("/customers/profile", newData);
    }
    async updateUserPassword(payload: UpdatePasswordRequest): Promise<void> {
        return this.instance.put("/customers/change-password", payload);
    }
    async indexUserContacts(): Promise<UserContactsResponse> {
        return this.instance.get("/customers/contacts");
    }

    async indexUserContractors() {
        return $AdaptedPaginatedResponse(z.array($Contractor)).parse(await this.instance.get("/customers/contractors"));
    }
    async createUserContractor(payload: CreateContractorRequest) {
        return this.instance.post("/customers/contractors", payload);
    }
    async indexUserDiscounts() {
        return $AdaptedResponse($Discount).parse(await this.instance.get("/v2/discounts"));
    }
    async indexUserStatistics() {
        return $AdaptedResponse($Statistic).parse(await this.instance.get("/customers/profile/statistics"));
    }

    async createUserContact(data: CreateUserContactRequest) {
        return this.instance.post("/customers/contacts", data);
    }

    async updateUserContact({ id, ...data }: UpdateUserContactRequest) {
        return this.instance.put(`/customers/contacts/${id}`, data);
    }

    async deleteUserContact({ id }: DeleteUserContactRequest) {
        return this.instance.delete(`/customers/contacts/${id}`);
    }

    async getUserContactRoleResources(): Promise<GetUserContactRoleResources> {
        const response = await this.instance.get("customers/contacts/roles");
        return $AdaptedResponse($GetUserContactRoleResources).parse(response);
    }
}

export const usersApi = new UsersApi(instance);
