import { createStyles } from "@mantine/core";

export const useStylesTypography = createStyles(() => ({
    dropMarginPadding: {
        margin: 0,
        padding: 0,
    },
    display: {
        fontWeight: 600,
        fontSize: 48,
        lineHeight: "56px",
        letterSpacing: "0.009em",
    },

    h1: {
        fontWeight: 600,
        fontSize: 32,
        lineHeight: "48px",
    },

    h2: {
        fontWeight: 600,
        fontSize: 24,
        lineHeight: "32px",
    },

    h3: {
        fontWeight: 600,
        fontSize: 20,
        lineHeight: "20px",
    },

    h4: {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: "20px",
        letterSpacing: "0.009em",
    },

    osRegular: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "20px",
    },

    osSemibold: {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: "20px",
    },

    osSmallRegular: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "20px",
    },

    osSmallSemibold: {
        fontWeight: 600,
        fontSize: 14,
        lineHeight: "20px",
    },

    mRegular: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "20px",
    },

    mMedium: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "20px",
        letterSpacing: "-0.01em",
    },

    mSemibold: {
        fontWeight: 600,
        fontSize: 14,
        lineHeight: "20px",
        letterSpacing: "-0.01em",
    },
    buttonSemibold: {
        fontWeight: 600,
        fontSize: 14,
        lineHeight: "20px",
        letterSpacing: "-0.01em",
    },

    mCaps: {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: "20px",
        textTransform: "uppercase",
    },
    buttonCaps: {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: "20px",
        textTransform: "uppercase",
    },

    mSmallCaps: {
        fontWeight: 700,
        fontSize: 13,
        lineHeight: "16px",
        textTransform: "uppercase",
    },
    buttonSmallCaps: {
        fontWeight: 700,
        fontSize: 13,
        lineHeight: "16px",
        textTransform: "uppercase",
    },

    adviceText: {
        fontWeight: 400,
        fontSize: 11,
        lineHeight: "14px",
    },
}));
