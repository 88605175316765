import { memo } from "react";
import { Box } from "@mantine/core";
import { ILink } from "shared/components/HoverMenu";
import { StyledLink } from "shared/ui/Link";
import Typography from "shared/ui/Typography/Typography";

interface Props {
    item: ILink;
}
export const HoverItem = memo(function HoverItem({ item }: Props) {
    return (
        <Box
            py={6}
            px={12}
            sx={{
                ":hover": {
                    div: {
                        fontWeight: "bold",
                    },
                },
            }}>
            <StyledLink href={item.href} style={{ justifyContent: "flex-start" }}>
                <Typography tag="mMedium">{item.text}</Typography>
            </StyledLink>
        </Box>
    );
});
