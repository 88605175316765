import { Box, Collapse, Flex, Footer as MantineFooter, Group, MediaQuery, Skeleton } from "@mantine/core";
import FooterMenuGroup from "entities/footer/ui/FooterMenuGroup";
import PersonalDataAccess from "features/footer/ui/PersonalDataAccess";
import Subscribe from "features/footer/ui/Subscribe";
import { IconLogo } from "public/icons/IconLogo";
import Typography from "shared/ui/Typography/Typography";
import { useGetMenu } from "widgets/footer/api/menuItems";

function Footer(): JSX.Element {
    const { menu, isLoading } = useGetMenu();

    return (
        <MediaQuery query="print" styles={{ display: "none" }}>
            <MantineFooter
                pt={40}
                pb={40}
                height="auto"
                sx={[
                    (theme) => ({
                        zIndex: "auto",
                        borderTop: `2px solid ${theme.colors.main_Black[0]}`,
                    }),
                ]}>
                <Collapse in={isLoading}>
                    <Flex gap={20}>
                        {Array.from(Array(7).keys()).map((_, i) => (
                            <Skeleton w={200} h={180} key={i} />
                        ))}
                    </Flex>
                </Collapse>
                <Collapse in={!isLoading}>
                    <Group spacing={65} position="apart" align="flex-start">
                        <nav>
                            <Flex gap={65} align="flex-start" wrap="wrap" justify="space-between">
                                {menu.map((item, index) => (
                                    <FooterMenuGroup key={`${item.title}_${index}`} {...item} />
                                ))}
                            </Flex>
                        </nav>
                        <Subscribe />
                    </Group>
                </Collapse>
                <Group mt={50} position="apart">
                    <Group>
                        <IconLogo />
                        <Box maw={500}>
                            <Typography tag="osSmallRegular">
                                {"© 2010-2024 vivagifts — Сувенирная продукция с нанесением логотипа.\n" +
                                    "Все права защищены.\n"}
                            </Typography>
                        </Box>
                    </Group>
                    <Box component="nav">
                        <PersonalDataAccess />
                    </Box>
                </Group>
            </MantineFooter>
        </MediaQuery>
    );
}

export default Footer;
